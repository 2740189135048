
import React from 'react';
// import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({
    introBlockWrap: {
      [theme.breakpoints.up(767)]: {
        paddingBottom: '40px',
      },
      [theme.breakpoints.up(960)]: {
        flexDirection:'row',
        alignItems: 'center'
      }
    },

    blockWrap: {
      backgroundColor: theme.palette.grey.main,
      padding: '50px',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up(767)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: '40px 55px',
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 57%',
        maxWidth: '57%',
      },
    },

    blockListWrap: {
      alignItems: 'flext-start',
      // textAlign: 'center',
      padding: '15px 40px',
      justifyContent: 'center',
      marginTop: 40,
      marginBottom: 40,
      [theme.breakpoints.up(767)]: {
        // alignItems: 'flex-start',
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0,
        padding: '40px'
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 43%',
        maxWidth: '43%',
        paddingLeft: '70px',
      },
    },

    blockText: {
      fontSize: 30,
      lineHeight: '36px',
      fontWeight: 600,
      [theme.breakpoints.up(767)]: {
        fontSize: 38,
        lineHeight: '42px',
        letterSpacing: '1px'
      },
    },

    listText: {
      fontWeight: 400,
      lineHeight: '36px',
      paddingLeft: 10,
      [theme.breakpoints.up(960)]: {
        paddingLeft: 0
      },
    }

  });

const IntroBlock = ({
  classes,
  text1,
  text2,
  text3,
  text4,
  value1,
  value2,
  value3,
  value4,
  blockText
  
}) => {

  return (

  <Grid
  container
  direction="column"
  className={classes.introBlockWrap}
>

  <Grid
      container
      className={classes.blockWrap}
      direction="column"

  >
      <Typography
          variant="body2"
          color= "primary"
          className={classes.blockText}
      >
          {blockText}

      </Typography>

  </Grid>

  <Grid
      container
      className={classes.blockListWrap}
      direction="column"

  >
      <Typography
          variant="body1"
          color= "primary"
          className={classes.listText}
      >
        <strong style={{ fontWeight: '600' }}>{value1}</strong>  {text1} 
       
      </Typography>
     
      <Typography
          variant="body1"
          color= "primary"
          className={classes.listText}
      >
         <strong style={{ fontWeight: '600' }}>{value2}</strong> {text2} 
       
      </Typography>
      
      <Typography
          variant="body1"
          color= "primary"
          className={classes.listText}
      >
         <strong style={{ fontWeight: '600' }}>{value3}</strong> {text3}
       
      </Typography>
      

      <Typography
          variant="body1"
          color= "primary"
          className={classes.listText}
      >
         <strong style={{ fontWeight: '600' }}>{value4}</strong> {text4} 
       
      </Typography>


  </Grid>

</Grid>


  );
};

  export default withStyles(styles, { withTheme: true })(IntroBlock);